.spinner {
  margin: 100px auto;
  width: 70px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 8px;
  display: inline-block;
  margin: 0 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;

  &.rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  &.rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  &.rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  &.rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}



@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}